import PropTypes from 'prop-types';

import Document from '../../icons/document';
import Download from '../../icons/download';
import Expand from '../../icons/expand';
import LegalBody from '../../typography/legal-body';

import styles from './document-button.css';

function DocumentButton({ onClick, title, subtitle, href }) {
  const content = (
    <>
      <Document />

      <div className={styles.content}>
        <LegalBody isBold>{title}</LegalBody>
        {subtitle ? <LegalBody>{subtitle}</LegalBody> : null}
      </div>

      <div className={styles.action}>{href ? <Expand /> : <Download />}</div>
    </>
  );

  return href ? (
    <a className={styles.root} href={href} target='_blank' rel='noreferrer'>
      {content}
    </a>
  ) : (
    <button className={styles.root} onClick={onClick}>
      {content}
    </button>
  );
}

function validateProp(props, propName, componentName) {
  if (!props.onClick && !props.href) {
    return new Error(`One of onClick or href must be supplied to ${componentName}`);
  }

  if (
    propName === 'onClick' &&
    !!props.onClick &&
    typeof props.onClick !== 'function'
  ) {
    return new Error('onClick must be a function');
  }

  if (propName === 'href' && !!props.href && typeof props.href !== 'string') {
    return new Error('href must be a string');
  }

  return null;
}

DocumentButton.propTypes = {
  onClick: validateProp,
  href: validateProp,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default DocumentButton;
