import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  Body1,
  ButtonStyles,
  ChevronRight,
  Heart,
  LegalBody,
  RouteLink as Link,
} from '@pumpkincare/shared/ui';

import { getPolicyConfig } from '../../policy-config-utils';

import styles from './pet-card.css';

function PetCard({ pet, toPet, toClaim, petPhoto, classes = {} }) {
  const { renewal, declined, cancelled, waiting, lapsed } = getPolicyConfig(
    pet.policies
  );

  return (
    <div className={styles.root}>
      <Link to={toPet} className={styles.top}>
        <div className={classNames(classes.imageWrapper, styles.imageWrapper)}>
          <img
            src={petPhoto}
            alt=''
            className={classNames(classes.petPhoto, styles.petPhoto)}
          />
        </div>
      </Link>

      <div className={styles.petCardWrapper}>
        <div className={styles.content}>
          <Link to={toPet} className={styles.top}>
            <div className={classNames(styles.name)}>
              <Body1 isBold>{pet.name}</Body1>
              <ChevronRight width={8} height={14} />
            </div>

            <div className={styles.cardCopy}>
              {cancelled.isCancelled && cancelled.isDeceased ? (
                <LegalBody className={styles.flex}>
                  In loving memory{' '}
                  <Heart className={styles.heart} width={21} height={16} />
                </LegalBody>
              ) : null}

              {cancelled.isCancelled && !cancelled.isDeceased ? (
                <LegalBody>Cancelled</LegalBody>
              ) : null}

              {declined.isDeclined ? (
                <LegalBody>Plan cancels on {declined.endDate}</LegalBody>
              ) : null}

              {waiting.isWaiting ? (
                <LegalBody>
                  {waiting.isModelLaw ? 'Illness w' : 'W'}aiting period until{' '}
                  <b>{waiting.startDate}</b>
                </LegalBody>
              ) : null}

              {renewal.isRenewal ? (
                <LegalBody>Renews on {renewal.renewalDate}</LegalBody>
              ) : null}

              {lapsed.isLapsed ? <LegalBody>Billing lapsed</LegalBody> : null}
            </div>
          </Link>

          {toClaim ? (
            <div className={styles.claim}>
              <Link
                to={toClaim}
                className={classNames(styles.button, ButtonStyles.tertiary)}
              >
                File a claim
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

PetCard.propTypes = {
  /** pet should just be the BE response associated with a pet */
  pet: PropTypes.object.isRequired,
  /** link for viewing more pet details */
  toPet: PropTypes.string.isRequired,
  /** link for filing a claim for a pet */
  toClaim: PropTypes.string,
  /** petPhoto should be the URL of the pet's photo */
  petPhoto: PropTypes.string,
  /** classes to pass to the component */
  classes: PropTypes.shape({
    imageWrapper: PropTypes.string,
    petPhoto: PropTypes.string,
  }),
};

export default PetCard;
