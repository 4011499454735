import { capitalize, formatFloat } from '@pumpkincare/shared';

const GENDER_GRAMMAR = {
  F: {
    subject: 'she',
    object: 'her',
    possessive: 'her',
  },
  M: {
    subject: 'he',
    object: 'him',
    possessive: 'his',
  },
};

export function transformPet(pet) {
  const genders = {
    M: 'male',
    F: 'female',
  };

  return {
    annual_limit: pet.annual_limit,
    copay: pet.copay,
    deductible: pet.deductible,
    hasPrevent: pet.has_prevent,
    id: pet.id,
    partner: pet.partner,
    petAge: pet.age,
    petBreedCode: pet.breed_code,
    petBreedName: pet.breed_name,
    petBreedSpecies: capitalize(pet.species),
    petBreedType: pet.breed_type,
    petDOB: pet.birth_date,
    petGender: genders[pet.gender],
    genderGrammar: GENDER_GRAMMAR[pet.gender],
    petName: pet.name,
    plan: pet.plan,

    preventativeSelected: pet.has_prevent, // deprecated
  };
}

export function transformPetRequest(pet) {
  const genders = {
    male: 'M',
    female: 'F',
  };

  return {
    annual_limit: pet.annual_limit,
    copay: pet.copay,
    deductible: pet.deductible,
    has_prevent: pet.hasPrevent,
    id: pet.id,
    partner: pet.partner,
    pet_age: pet.petAge,
    pet_gender: genders[pet.petGender],
    pet_name: pet.petName,
    breed_code: pet.petBreedCode,
    breed_name: pet.petBreedName,
    species: pet.petBreedSpecies,
    breed_type: pet.petBreedType,
    birth_date: pet.petDOB,
    plan: pet.plan,
  };
}

export function transformQuote(quote) {
  const {
    email,
    first_name,
    id,
    identity_id,
    is_registered,
    last_name,
    partner,
    policy_state,
    policy_zipcode,
    quote_pets = [],
    status,
    tracking_id,
    transaction_fee,
    vet,
  } = quote;

  return {
    email,
    firstName: first_name,
    id,
    identityId: identity_id,
    isRegistered: is_registered,
    lastName: last_name,
    partner,
    pets: quote_pets.map(transformPet),
    policyState: policy_state,
    policyZipcode: policy_zipcode,
    status,
    trackingId: tracking_id,
    transactionFee: transaction_fee,
    vet: vet || {},
  };
}

export function validateName(name) {
  return /^[a-zA-Z\s]+$/.test(name);
}

export function validatePet(pet) {
  return !!(pet.petName && pet.petBreedSpecies && validateName(pet.petName));
}

export function composeCheckoutLabel(price, hasPrevent) {
  let addons = 'Only';

  if (hasPrevent) {
    addons = '+ Preventive Essentials';
  }

  return `Insurance ${addons} — $${price.toFixed(2)} / mo`;
}

export function enrichPetsById(pets = [], addendum = {}) {
  return pets.map(pet => ({
    ...pet,
    ...addendum[pet.id],
  }));
}

export function calculateCheckoutTotals(
  pets = [],
  totalInsurancePrice = 0,
  priceMultiplier = 1
) {
  const preventTotal =
    formatFloat(
      pets
        .filter(pet => pet.preventativeSelected)
        .reduce((sum, pet) => sum + parseFloat(pet.plan?.cost), 0)
    ) * priceMultiplier;

  const grandTotal = formatFloat(totalInsurancePrice + preventTotal);

  return { preventTotal, grandTotal };
}

const LEGAL_SORT_ORDER = {
  'IMPORTANT SAFETY INFORMATION:': 0,
  '<span id="simparica-savings">PREVENTIVE ESSENTIALS PACKAGE SAVING INFORMATION:</span>': 1,
  '<span id="revolution-savings">PREVENTIVE ESSENTIALS PACKAGE SAVING INFORMATION:</span>': 1,
  'PRESCRIPTION FOOD COVERAGE TERMS:': 2,
};

export function sortLegalData(legal = []) {
  return legal.sort(function (a, b) {
    return LEGAL_SORT_ORDER[a.title] - LEGAL_SORT_ORDER[b.title];
  });
}

export function getSinglePetInsuranceTotal(petCostData) {
  let isThereDiscount = false;
  let priceAfterDiscount = parseFloat(petCostData.price_after_discount);

  let fullPrice = priceAfterDiscount;

  if (petCostData.discounts.length > 0) {
    isThereDiscount = true;

    fullPrice =
      priceAfterDiscount +
      petCostData.discounts.reduce(
        (sum, disc) => parseFloat(sum) + parseFloat(disc.amount),
        0
      );
  }

  return { fullPrice, priceAfterDiscount, isThereDiscount };
}
